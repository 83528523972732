export const MODULES = {
  USER: "Users",
  TIME_HISTORY: "Time History",
  APPROVE_TIMESHEET: "Approve Timesheet",
  TIMECLOCK_AUDIT_TRAIL:"Timeclock Audit Trail",
  TIME_CLOCK: "Time Clock",
  ROLES_RIGHTS: "Roles & Rights",
  TIMESHEET_REPORT: "Timesheet Report",
  CLOCK_IN_CLOCK_OUT: "Clock In (Clock Out)",
  ACCOUNT_LOOKUP: "Account Lookup",
  ORDER_LOOKUP: "Order Lookup",
  NEW_ORDER: "New Order",
  EDIT_ORDER: "Edit Order",
  PROCESS_ORDER: "Process Order",
  PENDING_ORDER: "Pending Order",
  FRAUD_ORDER: "Fraud Order",
  ONFLEET: "Onfleet",
  DESIGNER_STATUS: "Designer Status",
  VIEW_ORDER: "View Order",
  CATEGORY: "Category",
  PRODUCT_ADDONS: "Product - Add Ons",
  SALE_PRODUCT: "Sale Product",
  RECIPE: "Recipe",
  COUPON: "Coupon",
  DELIVERY_MATRIX: "Delivery Matrix",
  HOME_PAGE: "Home Page",
  LOCATIONS: "Locations",
  BLOGS: "Blogs",
  BLOGS_CATEGORY: "Blogs Category",
  HOLIDAYS_CUTOFF: "Holidays Cutoff",
  BLOCKOUT_DATES: "Blockout Dates",
  PETAL_REWARDS_CONFIG: "Petal Rewards Config",
  RECIPE_SETTINGS: "Recipe Settings",
  // STORE_SETTINGS: "Store Settings",
  // DESIGNER_SETTINGS: "Designer Settings",
  CONFIG_DESIGNER_SETTING: "Designer Setting",
  CONFIG_STORE_SETTING: "Store Setting",
  // USER_PROFILE: "User Profile"
  PAGES: "Pages",
  FAQ: "Faq",
  INVENTORY: "Inventory",
  REPORTS: "Reports",
  EXPORTS: "Export",
  MENU: "Menu",
  TIME_CLOCK_CONFIGURATION: "Time Clock Configuration",
  PRODUCT_ORDER_REPORT: "Product Order Report",
  AUDIT_TRAIL_LOG_REPORT: "Audit Trail Log Report",
  CANCEL_ORDER_REPORT: "Cancel Order Report",
  REPLACE_REFUND_ORDER_REPORT: "Replacements and Refunds",
  SHOP_CODE_REPORT: "Shop Code Report",
  COMMISSION_REPORT: "Commission Report",
  PRODUCTIVITY_REPORT: "Productivity Report",
  OFFICE_PRODUCTIVITY_REPORT: "Office Productivity Report",
  REVENUE_BREAKDOWN_REPORT: "Revenue Breakdown Report",
  STOCK_ARRANGEMENTS_REPORT: "Stock Arrangements Report",
  MOST_KEYWORD_SEARCH: "Most Keyword Search",
  ADMINISTRATIVE_REMINDERS: "Administrative",
  COMPLETED_ORDER: "Completed Order",
  DESIGNER_STATUS_PAGE_ACTION: "Designer Status Page Action",
  SUBSCRIPTION_LIST: "Subscription Order",
  INVENTORY_PRODUCTS: "Inventory",
  PRINT_ALL_MARK_AS_COMPLETED: "Print All/Mark All Completed",
  BUSINESS_REPORT: "Business Report",
  FLOWER_BUYER_REPORT: "Flower Buyer Report",
  ONDEMAND: "On-Demand",
  REQUEST_RESOURCES:"Request Resources",
  SEND_RESOURCES:"Send Resources",
  PRICING:"Pricing",
  WASTE_SHEET:"Waste Sheet"
}

export const MODULE_URLS = {
  [MODULES.USER]: "/admin/users/list",
  [MODULES.ROLES_RIGHTS]: "/admin/users/roles",
  [MODULES.APPROVE_TIMESHEET]: "/admin/time-clock/approve-timesheet",
  [MODULES.TIMECLOCK_AUDIT_TRAIL]: "/admin/time-clock/timeclock-audit-trail",
  [MODULES.TIME_CLOCK]: "/admin/settings/time-clock",
};

export const ACCESS_TYPES = {
  READ: "read_access",
  WRITE: "write_access",
  DELETE: "delete_access",
};

export const ORDER_STATUS = {
  PENDING: "Pending",
  PROCESSING: "Processing",
  IN_DESIGN: "In Design",
  DESIGN_COMPLETED: "Design Completed",
  OUT_FOR_DELIVERY: "Out for Delivery",
  DELIVERED: "Delivered",
  CANCEL: "Cancel",
  FAILED: "Failed",
};

export const ORDER_SOURCE = {
  CSR: "Associate Manual",
  WEBSITE: "Website",
  APP: "App",
  WALKIN: "Walkin",
};

export const PAYMENT_TYPE = {
  CREDIT_CARD: "Credit Card",
  DEBIT_CARD: "Debit Card",
  GOOGLE_PAY: "Google Pay",
  APPLE_PAY: "Apple Pay",
  PAYPAL: "Paypal",
  HOUSE_ACCOUNT: "House Account",
  CASH: "Cash",
};

// User type
export const USER_TYPES = [
  { id: "super_admin", title: "Super Admin" },
  { id: "admin", title: "Admin" },
  { id: "designer", title: "Designer" },
  { id: "sales_representative", title: "Sales Representative" },
  { id: "seo", title: "Seo" },
  { id: "driver", title:"Driver"}
];

export const CATEGORY_TYPES = [
  { id: "color", title: "Color" },
  { id: "brand", title: "Brand" },
  { id: "subcategory", title: "Sub Category" },
  { id: "kinds", title: "Kinds" },
  { id: "collection", title: "Collection" },
  { id: "location", title: "Location" },
];

export const STATUS_FILTER = [
  { id: "", title: "All" },
  { id: "1", title: "Active" },
  { id: "0", title: "In Active" },
];

export const LOCATION_TYPE = [
  { id: "STATE", title: "State" },
  { id: "CITY", title: "City" },
  { id: "BUSINESS", title: "Business" },
];

export const ORDER_STATUS_FILTER = [
  { id: "", title: "All" },
  { id: "pending", title: "Pending" },
  { id: "processing", title: "Processing" },
  { id: "in_design", title: "In Design" },
  { id: "design_completed", title: "Design Completed" },
  { id: "out_for_delivery", title: "Out for Delivery" },
  { id: "delivered", title: "Delivered" },
  { id: "cancel", title: "Cancel" },
  { id: "failed", title: "Failed" },
];

export const ORDER_TYPE = [
  { id: "", title: "All" },
  { id: "on_demand", title: "On-Demand" },
  { id: "on_fleet", title: "Onfleet" },
];

export const MANUAL_ORDER_STATUS_LIST = [
  { id: "", title: "All" },
  { id: "in_design", title: "In Design" },
  { id: "design_completed", title: "Design Completed" },
];

export const ONFLEET_ORDER_STATUS_LIST = [
  { id: "", title: "All" },
  { id: "taskUpdated", title: "Order Updated" },
  { id: "taskStarted", title: "Order Received" },
  { id: "taskArrival", title: "Order Shipped" },
  { id: "taskCompleted", title: "Order Delivered" },
  { id: "taskFailed", title: "Order Failed" },
  { id: "taskDelayed", title: "Order Delayed" },
];

export const BURQ_MANUAL_ORDER_STATUS_LIST = [
  { id: "", title: "All" },
  { id: "in_design", title: "In Design" },
  { id: "design_completed", title: "Design Completed" },
];

export const BURQ_ORDER_STATUS_LIST = [
  { id: "", title: "All" },
  { id: "pending", title: "Pending" },
  { id: "processing", title: "Processing" },
  { id: "in_design", title: "In Design" },
  { id: "design_completed", title: "Design Completed" },
  { id: "out_for_delivery", title: "Out for Delivery" },
  { id: "delivered", title: "Delivered" },
  { id: "cancel", title: "Cancel" },
  { id: "failed", title: "Failed" },
];

export const DEFAULT_COUNTRY_CODE = "+1";
export const DEFAULT_COUNTRY_CODE_NAME = "us";

export const FREQUENCY_LIST = {
  7: "Delivery once a week",
  14: "Delivery every other week",
  21: "Delivery every 3 weeks",
  28: "Delivery every 4 weeks"
};

export const STORE_LOCATION_SLUG_ARRAY = ["los-angeles","ventura","orange-county","scottsdale","tustin","los-angeles-hospitals","los-angeles-funeral-homes"];

export const REASON_LIST = {
  DESIGN_STYLE_ISSUE:{ id: 2, title: "Design Issue: Style" },
  DESIGN_SIZE_ISSUE:{ id: 3, title: "Design Issue: Size" },
  RECIPIENT_UNAVAILABLE_ISSUE:{ id: 4, title: "Recipient Issue: Unavailable" },
  RECIPIENT_BAD_ADDRESS_ISSUE:{ id: 5, title: "Recipient Issue: Bad address" },
  DISPATCH_ARRIVED_TOO_LATE_ISSUE:{ id: 6, title: "Dispatch Issue: Arrived too late" },
  DELIVERY_HOSPITAL_DOES_NOT_ALLOW_ISSUE:{ id: 7, title: "Delivery Issue: Hospital Does Not Allow" },
  DELIVERY_ARRANGEMENT_DAMAGED_IN_TRANSIT_ISSUE:{ id: 8, title: "Delivery Issue: Arrangement Damaged in Transit" },
  DELIVERY_DRIVER_DELIVERED_WRONG_ARRANGEMENT_ISSUE:{ id: 9, title: "Delivery Issue: Driver delivered wrong arrangement" },
  DELIVERY_DRIVER_FORGOT_ADD_ON_ITEM_ISSUE:{ id: 10, title: "Delivery Issue: Driver Forgot Add-On Item" },
  BAD_QUALITY_FLOWERS_USED_ISSUE:{ id: 11, title: "Quality Issue: Bad Quality Flowers Used" },
  SENDER_REQUESTED_CANCELLATION_IN_ADVANCE_ISSUE:{ id: 12, title: "Sender Issue: Sender Requested Cancellation In Advance" },
  OTHER:{ id: 1, title: "Other" },
}

export const DATE_TYPE = {
  FROM_DATE: "FROM_DATE",
  TO_DATE: "TO_DATE" 
}

export const REQUEST_STATUS = [
  {id: "pending_review", title: "Pending Review"},
  {id: "saved_as_draft", title: "Saved as Draft"},
  {id: "partially_filled", title: "Partially Filled"},
  {id: "filled", title:"Filled"}
]

export const REQUEST_TYPE = [
  {id: "manually", title: "Manually"},
  {id: "directly", title: "Directly"}
]

export const REQUEST_TYPE_FILTER = [
  { id: "", title: "All" },
  {id: "manually", title: "Manually"},
  {id: "directly", title: "Directly"}
];